<ng-template #header>
  <ng-container *ngIf="subscriber$ | async as subscriber">
    <ng-container *ngIf="isSmallScreen">
      <div class="d-block d-lg-none position-sticky top-0" *ngIf="displayAccountMenu" style="z-index: 10">
        <div class="header menu-account">
          <div class="d-flex gap-5 align-items-center h-100">
            <div class="align-self-center">
              <a class="color-tertiary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                 aria-controls="offcanvasExample">
                <fa-icon [icon]="fa.faBars" size="lg"></fa-icon>
              </a>
            </div>
            <div class="d-flex align-self-center justify-content-start flex-fill">
              <div class="bold color-tertiary text-capitalize">
                <ng-container *ngIf="subscriber!.profile_type === 'C'">
                  {{ subscriber.first_name }}
                </ng-container>
              </div>
              <ng-container *ngIf="subscriber!.profile_type === 'E'">
                {{ subscriber.first_name }}
              </ng-container>
            </div>
          </div>
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
               aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
              <div class="bold color-tertiary">
                <ng-container *ngIf="subscriber!.profile_type === 'C'">
                  {{ subscriber.first_name }}
                </ng-container>
                <ng-container *ngIf="subscriber!.profile_type === 'E'">
                </ng-container>

              </div>
              <button class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ng-container *ngIf="subscriber!.profile_type === 'C'">
                <app-status-candidate [subscriber]="subscriber"></app-status-candidate>

                <app-account-candidate-menu [subscriber]="subscriber" [offcanvas]="true"></app-account-candidate-menu>
              </ng-container>
              <ng-container *ngIf="subscriber!.profile_type === 'E'">
                <app-account-enterprise-menu [subscriber]="subscriber" [offcanvas]="true"></app-account-enterprise-menu>
              </ng-container>

            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-lg-none position-sticky top-0" *ngIf="displayProfileMenu" style="z-index: 10">
        <div class="header">
          <div class="d-flex gap-5 align-items-center h-100">
            <div class="align-self-center">
              <a class="color-tertiary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                 aria-controls="offcanvasExample">
                <fa-icon [icon]="fa.faBars" size="lg"></fa-icon>
              </a>
            </div>
            <div class="d-flex align-self-center justify-content-start flex-fill">
              <div class="bold color-tertiary text-capitalize">
                <ng-container *ngIf="subscriber!.profile_type === 'C'">
                  {{ subscriber.first_name }}
                </ng-container>
                <ng-container *ngIf="subscriber!.profile_type === 'E'">
                  Profil Entreprise
                </ng-container>
              </div>
            </div>
          </div>
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
               aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
              <div class="bold color-tertiary">
                <ng-container *ngIf="subscriber!.profile_type === 'C'">
                  Mon Profil
                </ng-container>
                <ng-container *ngIf="subscriber!.profile_type === 'E'">
                  Profil entreprise
                </ng-container>
              </div>
              <button class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ng-container *ngIf="subscriber!.profile_type === 'C'">
                <div class="d-flex flex-column justify-content-between h-100">
                  <div>
                    <h1 class="">Mon Profil</h1>
                    <app-profile-candidate-menu [offcanvas]="true"></app-profile-candidate-menu>
                  </div>

                  <div class="card gradient m-0 mt-4" style="box-shadow: unset; border: 0">
                    <div class="card-body">
                      <div class="d-flex flex-column align-items-start gap-3">
                        <div class="flex-fill text-center">
                          <img src="assets/images/gift.svg" width="56px">
                        </div>
                        <div class="">
                          <div class="d-flex flex-column gap-2">
                            <h2>Parrainage</h2>
                            <div>
                              Si nous trouvons un job à votre filleul(e), c’est 200€ à la clé pour vous !
                            </div>
                            <div>
                              <a [routerLink]="'/account/sponsorship'" class="btn btn-outline-primary">Découvrir
                                <fa-icon [icon]="fa.faCaretRight"></fa-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="subscriber!.profile_type === 'E'">
                <app-profile-enterprise-menu [offcanvas]="true"></app-profile-enterprise-menu>
              </ng-container>
            </div>
          </div>
        </div>

      </div>

      <div class="d-block d-lg-none position-sticky top-0" *ngIf="displaySearchMenu" style="z-index: 10">
        <div class="header menu-search">
          <div class="d-flex gap-5 align-items-center h-100">
            <div class="align-self-center">
              <a class="color-tertiary" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                 aria-controls="offcanvasExample">
                <fa-icon [icon]="fa.faBars" size="lg"></fa-icon>
              </a>
            </div>
            <div class="d-flex align-self-center justify-content-start flex-fill">
              <div class="bold color-tertiary text-capitalize">
                Candidats
              </div>
            </div>
          </div>
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
               aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <div class="bold color-tertiary">
                  Candidats
                </div>
              <button class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body p-0">
              <div class="h-100">
                <app-search-menu></app-search-menu>
              </div>

            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
<ng-template #menu>
  <ng-container *ngIf="business$ | async as business">
    <div class="sidebar" [ngClass]="{'pixel' : business === 2}">

      <!--    <div class="">-->

      <div class="d-flex flex-row h-100">
        <div class="d-flex flex-row flex-lg-column  flex-shrink-0 sidebar-icon ">
          <a [routerLink]="['/']" class="d-none d-lg-block p-3 link-dark text-decoration-none">
            <img src="assets/images/small-logo.png" style="width: 100%" *ngIf="business === 1">
            <img src="assets/images/small-logo-pixel.svg" style="width: 100%" *ngIf="business === 2">
          </a>
          <ul class="nav nav-pills nav-flush flex-row flex-lg-column mb-auto align-items-center color-secondary w-100"
              *ngIf="subscriber$ | async as subscriber">
            <ng-container *ngIf="subscriber!.profile_type === 'E'">
              <ng-container  *ngIf="eProfileMe$ | async as eProfile">
                <li class="nav-item flex-fill d-flex justify-content-center" [Tooltip]="'Recherche de candidats'" *ngIf="eProfile.business !== 2">
                  <a class="nav-link d-flex justify-content-center" [routerLink]="'/search'"
                     [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                    <fa-icon [icon]="fa.faSearch" size="lg" [fixedWidth]="true" class="align-self-center"></fa-icon>
                  </a>
                </li>
                <li class="nav-item flex-fill d-flex justify-content-center" [Tooltip]="'Recherche de candidats'" *ngIf="eProfile.business === 2">
                  <a class="nav-link d-flex justify-content-center" [routerLink]="'/search'"
                     [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                    <fa-icon [icon]="fa.faUsersViewfinder" size="lg" [fixedWidth]="true" class="align-self-center"></fa-icon>
                  </a>
                </li>

                <li class="nav-item flex-fill d-flex justify-content-center" [Tooltip]="'Recrutements'">
                  <a class="nav-link d-flex justify-content-center" [routerLink]="'/recruitment'"
                     [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                    <fa-icon [icon]="fa.faComments" size="lg" [fixedWidth]="true" class="align-self-center"></fa-icon>
                  </a>
                </li>
                <li class="nav-item flex-fill d-flex justify-content-center" [Tooltip]="'Profil de l\'entreprise'">
                  <a class="nav-link  d-flex justify-content-center position-relative" [routerLink]="'/profile/'"
                     [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                    <fa-icon [icon]="fa.faAddressCard" size="lg" [fixedWidth]="true" class="align-self-center"></fa-icon>
                    <div class="badge" *ngIf="profileNotFilled">&nbsp;</div>
                  </a>
                </li>
                <li class="nav-item flex-fill d-flex justify-content-center" [Tooltip]="'Notifications'">
                  <a class="nav-link d-flex justify-content-center position-relative" #notificationElement
                     id="menu-notifications"
                     (click)="offCanvasNotificationsComponent.toggle()">
                    <fa-icon [icon]="fa.faBell" size="lg" class="align-self-center"></fa-icon>
                    <div class="badge" *ngIf="notifications$ | async as notifications">
                      <ng-template [ngTemplateOutlet]="notif" #notif
                                   [ngTemplateOutletContext]="{'length' : notifications | filter : 'read_at' : null | count}"
                                   let-length="length">
                        <ng-container *ngIf="length !== 0">
                          {{ length }}
                        </ng-container>
                      </ng-template>
                    </div>
                  </a>
                </li>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="subscriber!.profile_type === 'C'">
              <li class="nav-item flex-fill flex-fill d-flex justify-content-center" [Tooltip]="'Tableau de bord'">
                <a class="nav-link d-flex justify-content-center" [routerLink]="'/dashboard'"
                   [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                  <fa-icon [icon]="fa.faHouse" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>
              <li class="nav-item flex-fill flex-fill d-flex justify-content-center" [Tooltip]="'Notifications'">
                <a class="nav-link d-flex justify-content-center position-relative" #notificationElement
                   id="menu-notifications"
                   (click)="offCanvasNotificationsComponent.toggle()">
                  <fa-icon [icon]="fa.faBell" size="lg" class="align-self-center"></fa-icon>
                  <div class="badge" *ngIf="notifications$ | async as notifications">
                    <ng-template [ngTemplateOutlet]="notif" #notif
                                 [ngTemplateOutletContext]="{'length' : notifications | filter : 'read_at' : null | count}"
                                 let-length="length">
                      <ng-container *ngIf="length !== 0">
                        {{ length }}
                      </ng-container>
                    </ng-template>
                  </div>
                </a>
              </li>
              <li class="nav-item flex-fill flex-fill d-flex justify-content-center" [Tooltip]="'Mes échanges'">
                <a class="nav-link d-flex justify-content-center" [routerLink]="'/recruitment'"
                   [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                  <fa-icon [icon]="fa.faComments" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>
              <li class="nav-item flex-fill flex-fill d-flex justify-content-center" [Tooltip]="'Mon profil'">
                <a class="nav-link d-flex justify-content-center" [routerLink]="'/profile/'"
                   [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                  <fa-icon [icon]="fa.faAddressCard" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>

            </ng-container>
            <hr class="px-4 d-none d-lg-block" *ngIf="subscriber.id">

            <ng-container *ngIf="subscriber!.profile_type === 'E'">

              <li class="nav-item flex-fill flex-fill d-flex justify-content-center d-none d-lg-block"
                  [Tooltip]="'Foire aux questions'">
                <a class="nav-link d-flex justify-content-center" [href]="('' | environment).PUBLIC_URL + 'faq'"
                   [routerLinkActive]="'active'" target="_blank">
                  <fa-icon [icon]="fa.faCircleQuestion" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>

              <li class="nav-item flex-fill flex-fill d-flex justify-content-center d-none d-lg-block"
                  [Tooltip]="'Mes outils connectés'">
                <a class="nav-link d-flex justify-content-center" [routerLink]="'/account/tools'"
                   [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                  <fa-icon [icon]="fa.faPuzzlePiece" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>
            </ng-container>

            <ng-container *ngIf="subscriber!.profile_type === 'C'">

              <li class="nav-item flex-fill flex-fill d-flex justify-content-center d-none d-lg-block"
                  [Tooltip]="'Foire aux questions'">
                <a class="nav-link d-flex justify-content-center" [href]="('' | environment).PUBLIC_URL + 'faq'"
                   [routerLinkActive]="'active'" target="_blank">
                  <fa-icon [icon]="fa.faCircleQuestion" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>

              <li class="nav-item flex-fill flex-fill d-flex justify-content-center d-none d-lg-block"
                  [Tooltip]="'Parrainage'">
                <a class="nav-link d-flex justify-content-center" [routerLink]="'/account/sponsorship'"
                   [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                  <fa-icon [icon]="fa.faGift" size="lg" class="align-self-center"></fa-icon>
                </a>
              </li>
            </ng-container>

            <li class="nav-item flex-fill flex-fill d-flex justify-content-center" *ngIf="subscriber.id">
              <a class="nav-link d-flex justify-content-center position-relative link-avatar  p-0"
                 [Tooltip]="'Mon compte'"
                 [routerLink]="'/account/personal'"
                 [routerLinkActive]="'active'" (click)="offCanvasNotificationsComponent.hide()">
                <app-avatar *ngIf="subscriber!.last_name" [subscriber]="subscriber" size=""
                            class="align-self-center"></app-avatar>
                <div class="badge" *ngIf="accountNotFilled">&nbsp;</div>
              </a>
            </li>
          </ul>

          <ng-container *ngIf="!('' | environment).production">
            <div class="d-flex flex-column gap-3 align-items-center"  *ngIf="eProfileMe$ | async as eProfile">
              <div>
                <button class="btn btn-outline-primary p-2" (click)="addDay()" [Tooltip]="'Ajouter un jour'">+1</button>
              </div>

              <div>
                <button class="btn p-2" [Tooltip]="'standard'" (click)="updateBusiness(1)"
                        [ngClass]="(eProfile.business == 1) ? 'btn-primary' : 'btn-secondary'">ST
                </button>
              </div>
              <div>
                <button class="btn p-2" [Tooltip]="'pixel'" (click)="updateBusiness(2)"
                        [ngClass]="eProfile.business == 2 ? 'btn-primary' : 'btn-secondary'">PX
                </button>
              </div>
              <div>
                <button class="btn p-2" [Tooltip]="'standard + pixel'" (click)="updateBusiness(3)"
                        [ngClass]="(eProfile.business == 3) ? 'btn-primary' : 'btn-secondary'">SP
                </button>
              </div>
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="!isSmallScreen">
          <div class="menu-account d-none d-lg-block" *ngIf="displayAccountMenu">
            <ng-container *ngIf="subscriber$ | async as subscriber">
              <h1 class="text-capitalize">{{ subscriber.first_name }}</h1>

              <ng-container *ngIf="subscriber!.profile_type === 'E'">
                <app-account-enterprise-menu [subscriber]="subscriber"></app-account-enterprise-menu>
              </ng-container>

              <ng-container *ngIf="subscriber!.profile_type === 'C'">
                <app-status-candidate [subscriber]="subscriber"></app-status-candidate>
                <app-account-candidate-menu [subscriber]="subscriber"></app-account-candidate-menu>
              </ng-container>
            </ng-container>
          </div>

          <div class="menu-profile d-none d-lg-block overflow-auto scrollbar" *ngIf="displayProfileMenu">
            <ng-container *ngIf="subscriber$ | async as subscriber">

              <ng-container *ngIf="subscriber!.profile_type === 'E'">
                <div>
                  <h1 class="">Profil entreprise</h1>
                  <div class="d-flex gap-2">
                    <!--                  <app-avatar [eProfile]=""></app-avatar>-->
                  </div>
                  <app-profile-enterprise-menu></app-profile-enterprise-menu>
                </div>
              </ng-container>

              <ng-container *ngIf="subscriber!.profile_type === 'C'">

                <div class="d-flex flex-column justify-content-between h-100">
                  <div>
                    <h1 class="">Mon Profil</h1>
                    <app-profile-candidate-menu></app-profile-candidate-menu>
                  </div>

                  <div class="card gradient m-0 mt-4" style="box-shadow: unset; border: 0">
                    <div class="card-body">
                      <div class="d-flex flex-column align-items-start gap-3">
                        <div class="flex-fill text-center">
                          <img src="assets/images/gift.svg" width="56px">
                        </div>
                        <div class="">
                          <div class="d-flex flex-column gap-2">
                            <h2>Parrainage</h2>
                            <div>
                              Si nous trouvons un job à votre filleul(e), c’est 200€ à la clé pour vous !
                            </div>
                            <div>
                              <a [routerLink]="'/account/sponsorship'" class="btn btn-outline-primary">Découvrir
                                <fa-icon [icon]="fa.faCaretRight"></fa-icon>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </div>

          <div class="menu-search d-none d-lg-block" *ngIf="displaySearchMenu">
            <div class="h-100">
              <app-search-menu></app-search-menu>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <app-offCanvas-notifications #offCanvasNotificationsComponent [notifications$]="notifications$" [read]="read"
                                 [readAll]="readAll" [notificationDisplay$]="notificationDisplay$">
    </app-offCanvas-notifications>
  </ng-container>
</ng-template>
